<script lang="tsx">
import { defineComponentCoreIcon } from '@core/app/components/core/icon/CoreIcon.vue'

export default defineComponentCoreIcon(
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 25 25">
        <path stroke="currentColor"
            stroke-linecap="round"
            stroke-width="1.8"
            d="M7.42841 9.09619V20.3925c0 .5523.44771 1 1 1h8.94989c.5523 0 1-.4477 1-1V9.09619M5.02411 6.11328H21.1611"
        />
        <path stroke="currentColor"
            stroke-width="1.8"
            d="M10.6879 5.6347v-.29487c0-1.22349.9919-2.21532 2.2153-2.21532 1.2235 0 2.2154.99183 2.2154 2.21532v.29487"
        />
    </svg>
)

</script>

<style lang="scss" scoped>

</style>
